exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/aboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-left-sidebar-jsx": () => import("./../../../src/pages/blog-left-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-left-sidebar-jsx" */),
  "component---src-pages-blog-right-sidebar-jsx": () => import("./../../../src/pages/blog-right-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-right-sidebar-jsx" */),
  "component---src-pages-celebrating-nationals-win-jsx": () => import("./../../../src/pages/celebrating-nationals-win.jsx" /* webpackChunkName: "component---src-pages-celebrating-nationals-win-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-dcc-tournament-jsx": () => import("./../../../src/pages/DCC-Tournament.jsx" /* webpackChunkName: "component---src-pages-dcc-tournament-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-middletown-jsx": () => import("./../../../src/pages/Middletown.jsx" /* webpackChunkName: "component---src-pages-middletown-jsx" */),
  "component---src-pages-njcc-gallery-jsx": () => import("./../../../src/pages/NJCC_Gallery.jsx" /* webpackChunkName: "component---src-pages-njcc-gallery-jsx" */),
  "component---src-pages-njcc-jsx": () => import("./../../../src/pages/NJCC.jsx" /* webpackChunkName: "component---src-pages-njcc-jsx" */),
  "component---src-pages-njcc-masterclass-jsx": () => import("./../../../src/pages/NJCC-Masterclass.jsx" /* webpackChunkName: "component---src-pages-njcc-masterclass-jsx" */),
  "component---src-pages-njcc-tournament-jsx": () => import("./../../../src/pages/NJCC-Tournament.jsx" /* webpackChunkName: "component---src-pages-njcc-tournament-jsx" */),
  "component---src-pages-online-basics-of-chess-jsx": () => import("./../../../src/pages/online-basics-of-chess.jsx" /* webpackChunkName: "component---src-pages-online-basics-of-chess-jsx" */),
  "component---src-pages-online-store-jsx": () => import("./../../../src/pages/online-store.jsx" /* webpackChunkName: "component---src-pages-online-store-jsx" */),
  "component---src-pages-programs-jsx": () => import("./../../../src/pages/programs.jsx" /* webpackChunkName: "component---src-pages-programs-jsx" */),
  "component---src-pages-training-curriculum-jsx": () => import("./../../../src/pages/training-curriculum.jsx" /* webpackChunkName: "component---src-pages-training-curriculum-jsx" */),
  "component---src-pages-wilmington-chess-program-jsx": () => import("./../../../src/pages/Wilmington-Chess-Program.jsx" /* webpackChunkName: "component---src-pages-wilmington-chess-program-jsx" */),
  "component---src-templates-categories-post-index-jsx": () => import("./../../../src/templates/categories-post/index.jsx" /* webpackChunkName: "component---src-templates-categories-post-index-jsx" */),
  "component---src-templates-services-details-index-jsx": () => import("./../../../src/templates/services-details/index.jsx" /* webpackChunkName: "component---src-templates-services-details-index-jsx" */),
  "component---src-templates-single-post-1-index-jsx": () => import("./../../../src/templates/single-post1/index.jsx" /* webpackChunkName: "component---src-templates-single-post-1-index-jsx" */),
  "component---src-templates-single-post-2-index-jsx": () => import("./../../../src/templates/single-post2/index.jsx" /* webpackChunkName: "component---src-templates-single-post-2-index-jsx" */),
  "component---src-templates-single-post-3-index-jsx": () => import("./../../../src/templates/single-post3/index.jsx" /* webpackChunkName: "component---src-templates-single-post-3-index-jsx" */),
  "component---src-templates-single-post-4-index-jsx": () => import("./../../../src/templates/single-post4/index.jsx" /* webpackChunkName: "component---src-templates-single-post-4-index-jsx" */),
  "component---src-templates-single-post-5-index-jsx": () => import("./../../../src/templates/single-post5/index.jsx" /* webpackChunkName: "component---src-templates-single-post-5-index-jsx" */),
  "component---src-templates-single-post-6-index-jsx": () => import("./../../../src/templates/single-post6/index.jsx" /* webpackChunkName: "component---src-templates-single-post-6-index-jsx" */),
  "component---src-templates-tag-post-index-jsx": () => import("./../../../src/templates/tag-post/index.jsx" /* webpackChunkName: "component---src-templates-tag-post-index-jsx" */)
}

